exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apartmea-tsx": () => import("./../../../src/pages/apartmea.tsx" /* webpackChunkName: "component---src-pages-apartmea-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-frango-tsx": () => import("./../../../src/pages/frango.tsx" /* webpackChunkName: "component---src-pages-frango-tsx" */),
  "component---src-pages-hate-free-zone-tsx": () => import("./../../../src/pages/hate-free-zone.tsx" /* webpackChunkName: "component---src-pages-hate-free-zone-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-quest-bar-tsx": () => import("./../../../src/pages/quest-bar.tsx" /* webpackChunkName: "component---src-pages-quest-bar-tsx" */),
  "component---src-pages-soupculture-tsx": () => import("./../../../src/pages/soupculture.tsx" /* webpackChunkName: "component---src-pages-soupculture-tsx" */),
  "component---src-pages-sukromie-tsx": () => import("./../../../src/pages/sukromie.tsx" /* webpackChunkName: "component---src-pages-sukromie-tsx" */)
}

